import React from 'react';
import styled from 'styled-components';
import { slidingAnimation } from 'styles/utilities/index';
import Nft from 'svgs/expertises/nfts.svg';

const SAnimatedNfts = styled.div`
    width: 100%;
    height: 100%;
    #shape-1 {
        ${slidingAnimation(10, 10)};
    }
    #shape-2 {
        ${slidingAnimation(8, 8)};
    }
    #shape-3 {
        ${slidingAnimation(0, 10)};
    }
    #shape-3 {
        ${slidingAnimation(0, 10)};
    }
    #shape-4 {
        ${slidingAnimation(-10, -10)};
    }
    #circle {
        ${slidingAnimation(0, 8)};
    }
    #coin {
        ${slidingAnimation(0, 10)};
    }
    #page {
        ${slidingAnimation(0, 10)};
    }
    #flipped-coin-1 {
        ${slidingAnimation(0, -5)};
    }
    #flipped-coin-2 {
        ${slidingAnimation(0, -5)};
    }
    #flipped-coin-3 {
        ${slidingAnimation(0, -5)};
    }
`;

export const AnimatedNfts = () => {
    return (
        <SAnimatedNfts>
            <Nft width="100%" height="100%" />
        </SAnimatedNfts>
    );
};
