import React from 'react';
import styled from 'styled-components';
import { bool } from 'prop-types';
import ArrowIcon from 'svgs/icons/arrow-right-2.svg';
import SlimArrowIcon from 'svgs/icons/ic-slim-arrow.svg';

const SArrow = styled.div`
    height: 24px;
    .bg-path {
        transition: fill 0.3s ease-in-out;
        fill: transparent;
    }
    .arrow {
        transition: stroke 0.3s ease-in-out;
        stroke: var(--primary-color);
    }

    &:hover {
        .bg-path {
            fill: var(--primary-color);
        }
        .arrow {
            stroke: var(--white-color);
        }
    }
`;

export const Arrow = ({ isSlim, className }) => {
    if (isSlim) {
        return <SlimArrowIcon />;
    }
    return (
        <SArrow className={className}>
            <ArrowIcon height={24} width={24} />
        </SArrow>
    );
};

Arrow.propTypes = {
    isSlim: bool,
};

Arrow.defaultProps = {
    isSlim: false,
};
