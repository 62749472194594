import React from 'react';
import { OurServicesSection } from 'modules/our-services-section';

import { OUR_SERVICES } from './constant';

export const ServicesSection = () => {
    return (
        <OurServicesSection
            title="expertises.ourServiceLines"
            paragraph="expertises.talentedProfessionals"
            data={OUR_SERVICES}
        />
    );
};
