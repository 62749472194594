import React from 'react';
import styled, { css } from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { node, string } from 'prop-types';

const SBullet = styled.div`
    position: absolute;
    top: 0.25rem;
    left: -2.25rem;
    ${CONSTANTS.MEDIA.max1024`
        display: none;
    `}
`;

const SStyledLi = styled.li`
    font-size: 1rem;
    font-weight: 300;
    position: relative;
    list-style: none;

    &:before {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        top: 12px;
        left: -1.375rem;
        background-color: var(--medium-red-color);
        ${({ bullet }) =>
            bullet &&
            css`
                display: none;
            `}
    }
`;

export const StyledLi = (props) => {
    const { children, className, bullet } = props;
    return (
        <SStyledLi className={className} bullet={bullet}>
            {bullet && <SBullet>{bullet}</SBullet>}
            {children}
        </SStyledLi>
    );
};

StyledLi.propTypes = {
    bullet: node,
    children: node.isRequired,
    className: string,
};

StyledLi.defaultProps = {
    bullet: undefined,
    className: '',
};
