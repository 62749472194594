import React from 'react';
import { AnimatedFintech } from 'components/animated-fintech';
import { AnimatedHealthcare } from 'components/animated-healthcare';
import { AnimatedNfts } from 'components/animated-nfts';
import { AnimatedRealEstate } from 'components/animated-real-estate';
import { AnimatedStreamingSolutions } from 'components/animated-streaming-solutions';
import { PATHS } from 'constants/paths';

export const OUR_SERVICES = [
    {
        id: 'real-estate-prop-tech',
        title: 'expertises.realEstatePropTech',
        description: 'expertises.theDevelopmentOfPropTech',
        url: `${PATHS.EXPERTISES}/${PATHS.PROP_TECH_REAL_ESTATE}`,
        img: <AnimatedRealEstate />,
    },
    {
        id: 'streaming-solutions',
        title: 'expertises.streamingSolutions',
        description: 'expertises.developingAnIndependent',
        url: `${PATHS.EXPERTISES}/${PATHS.AUDIO_VIDEO}`,
        isReversed: true,
        img: <AnimatedStreamingSolutions />,
    },
    {
        id: 'fintech-banking',
        title: 'expertises.fintechBanking',
        description: 'expertises.theDevelopmentOfMobile',
        url: `${PATHS.EXPERTISES}/${PATHS.FINTECH}`,
        img: <AnimatedFintech />,
        isReversed: true,
    },
    {
        id: 'nftBlockchain',
        title: 'expertises.nftBlockchain',
        description: 'expertises.customEnterpriseBlockchain',
        url: `${PATHS.EXPERTISES}/${PATHS.NFT}`,
        img: <AnimatedNfts />,
        isDark: true,
    },
];
