import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { StyledLi } from 'components/styled-li';
import { CONSTANTS } from 'constants/styles/constants';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { array, arrayOf, bool, func, object, shape, string } from 'prop-types';
import ShieldTick from 'svgs/icons/ic-shield-tick.svg';
import { isGatsbyImage } from 'utilities';

const SWrapper = styled.div`
    display: flex;
    gap: 5.5rem;
    flex-direction: ${({ isReversed }) => isReversed && 'row-reverse'};
    align-items: center;

    ${CONSTANTS.MEDIA.max1024`
        flex-direction: column;
        gap: 4rem;
    `}
`;

const STitle = styled(HeaderSecond)`
    font-size: 2.25rem;
    margin-bottom: 1.25rem;
`;

const SCol = styled.div`
    flex: 0.5;
    width: 100%;
    ${({ textColumnCss }) => textColumnCss};
`;

const SImage = styled(GatsbyImage)`
    width: 100%;
    height: 100%;
`;

const SParagraph = styled(Paragraph)`
    &:not(:last-child) {
        margin-bottom: 1rem;
    }
`;

const SIconWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    ${CONSTANTS.MEDIA.max1024`
       max-width: 28.125rem;
    `}
`;

const SButton = styled(Button)`
    margin-top: 2rem;
`;

const SUl = styled.ul`
    display: flex;
    gap: 2.25rem;
    flex-direction: column;
`;

const SStyledLi = styled(StyledLi)`
    &:before {
        display: none;
    }
`;

const SSpan = styled.span`
    font-weight: bold;
    font-size: 1.375rem;
    display: block;
`;

export const RowWithTextImg = ({
    title,
    descriptions,
    img,
    alt,
    className,
    isReversed,
    button,
    list,
    textColumnCss,
    isDescriptionFormattedPreviously,
}) => {
    const listToRender = list?.map(({ title, paragraph }) => (
        <SStyledLi key={title} bullet={<ShieldTick width={24} height={24} />}>
            <SSpan>
                <FormattedMessage id={title} />
            </SSpan>
            <FormattedMessage id={paragraph} />
        </SStyledLi>
    ));

    const resolveButton = () => {
        if (!button) {
            return null;
        }

        let params;
        if (button.to) {
            params = { to: button.to, component: 'gatsby-link' };
        } else if (button.onClick) {
            params = { onClick: button.onClick, component: 'button' };
        }

        return (
            <SButton title={button.title} variant={button.variant} {...params}>
                <FormattedMessage id={button.title} />
            </SButton>
        );
    };

    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SWrapper className={className} isReversed={isReversed}>
            <SCol textColumnCss={textColumnCss}>
                {title && (
                    <STitle id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                        <FormattedMessage id={title} />
                    </STitle>
                )}
                {descriptions?.map((text) => (
                    <SParagraph key={text}>
                        {isDescriptionFormattedPreviously ? (
                            text
                        ) : (
                            <FormattedMessage id={text} />
                        )}
                    </SParagraph>
                ))}
                {resolveButton()}
                {list && <SUl>{listToRender}</SUl>}
            </SCol>
            <SCol>
                {isGatsbyImage(img) ? (
                    <SImage
                        image={img.childImageSharp.gatsbyImageData}
                        imgStyle={{
                            objectFit: 'scale-down',
                            objectPosition: 'contain',
                            width: '100%',
                        }}
                        alt={alt}
                    />
                ) : (
                    <SIconWrapper>{img}</SIconWrapper>
                )}
            </SCol>
        </SWrapper>
    );
};

RowWithTextImg.propTypes = {
    title: string,
    descriptions: array.isRequired,
    img: object.isRequired,
    alt: string,
    className: string,
    isReversed: bool,
    isDescriptionFormattedPreviously: bool,
    textColumnCss: arrayOf(string),
    button: shape({
        title: string,
        to: string,
        variant: string,
        onClick: func,
    }),
    list: arrayOf(
        shape({
            title: string,
            paragraph: string,
        }),
    ),
};

RowWithTextImg.defaultProps = {
    title: '',
    className: '',
    isReversed: false,
    button: undefined,
    list: [],
    textColumnCss: [''],
    isDescriptionFormattedPreviously: false,
};
