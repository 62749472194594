import React from 'react';
import styled from 'styled-components';
import { slidingAnimation } from 'styles/utilities/index';
import StreamingSolutions from 'svgs/expertises/streaming-solutions.svg';

const SAnimatedStreamingSolutions = styled.div`
    height: 100%;
    width: 100%;
    #message-1 {
        ${slidingAnimation(0, 10)};
    }
    #message-2 {
        ${slidingAnimation(5, -10)};
    }
    #shape-1 {
        ${slidingAnimation(-10, 10)};
    }
    #shape {
        ${slidingAnimation(10, 10)};
    }
    #dot-1 {
        ${slidingAnimation(5, 5)};
    }
    #camera {
        ${slidingAnimation(-10, -10)};
    }
    #avatar {
        ${slidingAnimation(0, -10)};
    }
    #circle {
        ${slidingAnimation(0, -5)};
    }
`;

export const AnimatedStreamingSolutions = () => {
    return (
        <SAnimatedStreamingSolutions>
            <StreamingSolutions width="100%" height="100%" />
        </SAnimatedStreamingSolutions>
    );
};
