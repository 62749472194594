import React from 'react';
import { ExpertisesView } from 'views/expertises';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/expertises/index.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const ExpertisesPage = (props) => {
    const { location } = props;
    return (
        <Layout location={location} messages={messages}>
            <ExpertisesView />
        </Layout>
    );
};

ExpertisesPage.propTypes = {
    location: object.isRequired,
};

export default ExpertisesPage;

export const Head = () => <SEO tags={TAGS.EXPERTISES} />;
