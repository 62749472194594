import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { arrayOf, node, object, oneOfType, shape, string } from 'prop-types';

import { SingleService } from './components/single-service';

const SContainer = styled(Container)`
    text-align: center;
`;

const SHeader = styled(HeaderSecond)`
    margin-bottom: 1.5rem;
`;

const SWrapper = styled.div`
    margin-top: 4.375rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    ${({ wrapperCustomStyles }) => ({ ...wrapperCustomStyles })};
`;

export const OurServicesSection = ({
    title,
    paragraph,
    data,
    wrapperCustomStyles,
}) => {
    const noTopPadding = (index) => {
        if (index === 0 && !title && !paragraph) {
            return { paddingTop: 0 };
        }
    };

    return (
        <SContainer>
            {title && (
                <SHeader>
                    <FormattedMessage id={title} />
                </SHeader>
            )}
            {paragraph && (
                <Paragraph>
                    <FormattedMessage id={paragraph} />
                </Paragraph>
            )}
            <SWrapper wrapperCustomStyles={wrapperCustomStyles}>
                {data.map(({ id, ...rest }, index) => (
                    <SingleService
                        key={id}
                        sectionCustomStyles={noTopPadding(index)}
                        {...rest}
                    />
                ))}
            </SWrapper>
        </SContainer>
    );
};

OurServicesSection.propTypes = {
    title: string,
    paragraph: string,
    data: arrayOf(
        shape({
            id: string,
            title: string,
            description: string,
            url: string,
            img: oneOfType([node.isRequired, object.isRequired]),
        }),
    ).isRequired,
    wrapperCustomStyles: object,
};
